@tailwind base;
@tailwind components;

* {
  @apply touch-manipulation;
}

body {
  @apply bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-100 font-sans p-1 touch-manipulation;
	background-image: url(https://directivecreator.com/griddle/gbackground.png);
}

.app {
  @apply container max-w-md mx-auto flex flex-col justify-between items-center touch-manipulation max-h-screen;

  height: 92vh;
}

.title-container {
  @apply border-b-black dark:border-b-gray-100 border-b w-full flex flex-row justify-between;

  h1 {
    @apply text-4xl font-bold text-center uppercase mb-1 tracking-widest;
    small {
      @apply ml-1 text-sm font-normal tracking-tight lowercase;
    }
  }
}

.guesses-container {
  @apply mb-4 touch-manipulation;

  .guess-row {
    @apply flex touch-manipulation;
  }

  .guess {
    @apply md:w-16 md:h-16 w-14 h-14 border-2 border-gray-600 box-border flex justify-center items-center text-gray-900 dark:text-gray-50 m-1 text-3xl font-black;
  }
}

.keyboard-container {
  @apply w-full flex flex-wrap justify-center gap-1 px-2 touch-manipulation;

  .key {
    @apply md:h-12 h-10 w-auto rounded-md border-0 flex flex-grow text-sm md:text-base bg-gray-400 text-white items-center justify-center touch-manipulation;

    min-width: 1.65rem;
  }

  .key:nth-child(12) {
    @apply ml-2;
  }

  .key:nth-child(20) {
    @apply mr-2;
  }

  .key:nth-child(22),
  .key:nth-child(30) {
    min-width: 2rem;
  }
}

.present-overlay {
  background-color: #b59f3b !important;
  border: none !important;
  color: #dedede !important;
  touch-action: touch-manipulation;
}

.correct-overlay {
  background-color: #538d4e !important;
  border: none !important;
  color: #dedede !important;
  touch-action: touch-manipulation;
}

.absent-overlay {
  background-color: #3a3a3c !important;
  border: none !important;
  color: #dedede !important;
  touch-action: touch-manipulation;
}

.break-flex {
  flex-basis: 100%;
  height: 0;
  touch-action: touch-manipulation;
}

svg.icon {
  @apply mt-2 cursor-pointer;

  path {
    fill: #666;
  }
}

.instructions,
.stats {
  * {
    @apply font-sans;
  }

  h1,
  h2 {
    @apply text-lg font-bold mb-2;
  }

  p {
    @apply text-base mb-4;
  }

  hr {
    @apply my-2;
  }
}

.instructions {
  .guess-row {
    @apply flex touch-manipulation justify-center;
  }

  .guess {
    @apply md:h-10 w-10 h-10 border-2 border-gray-600 box-border flex justify-center items-center text-gray-900 dark:text-gray-50 m-1 text-2xl font-black uppercase;
  }

  .examples-text {
    @apply text-sm;
  }
}

.stats {
  h1 {
    @apply uppercase text-sm;
  }

  .btn-share {
    @apply w-full text-3xl bg-purple-900 p-4 rounded-lg uppercase text-gray-50;
  }

  .btn-share:hover {
    @apply bg-purple-700;
  }
}

#guess-distribution.bar {
  --labels-size: 30px;
  @apply text-sm;

  span.data {
    @apply pr-2;
  }
}

/* animations from https://animista.net/ */
.flip-vertical-right {
  animation: flip-vertical-right 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) reverse both;
}

@keyframes flip-vertical-right {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(180deg);
  }
}

.shake-horizontal {
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

@keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }

  20%,
  40%,
  60% {
    transform: translateX(10px);
  }

  80% {
    transform: translateX(8px);
  }

  90% {
    transform: translateX(-8px);
  }
}

.fade-in {
  animation: fade-in 1.2s ease-in both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fade-out 1s ease-out both;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@tailwind utilities;
